<template>
  <!-- <div> -->
  <baidu-map class="map"
    ak="L2s5R0CE405alnjKlsp35mNTySNAvZtd"
    style="height: 100vh;width:100%;"
    :center="{lng: 112.480067, lat: 34.685459 }"
    :zoom="18"
    @mousemove="syncPolyline"
    @click="paintPolyline"
    @rightclick="newPolyline">
    <!-- <bm-control>
      <button @click="toggle('polyline')">{{ polyline.editing ? '停止绘制' : '开始绘制' }}</button>
    </bm-control> -->
    <!-- <div v-for="(item,index) in area"
      :key="index">
      <span>{{item}}</span> -->
    <bm-polyline v-for="(a, index) in area"
      :key="'b' + index"
      :path="a"
      :stroke-color="xunjieList.Color"
      :stroke-opacity="0.5"
      :stroke-weight="2"></bm-polyline>
    <!-- </div> -->

  </baidu-map>
  <!-- </div> -->
</template>
<script>
export default {
  name: "Bmaptrace",
  data () {
    return {
      polyline: {
        editing: false,
        paths: []
      },
      map: {}, // 将map设置为全局变量
      polylinePath: [],
      xunjieList: {},
      area: [],
    };
  },
  mounted () {
    this.xunjieList = JSON.parse(this.$route.params.id); //传递参数编译
    console.log(JSON.parse(this.xunjieList.AreaRange));
    this.area = JSON.parse(this.xunjieList.AreaRange)
    // console.log(this.area[0]);
  },
  methods: {
    toggle (name) {
      this[name].editing = !this[name].editing
    },
    syncPolyline (e) {
      if (!this.polyline.editing) {
        return
      }
      const { paths } = this.polyline
      if (!paths.length) {
        return
      }
      const path = paths[paths.length - 1]
      if (!path.length) {
        return
      }
      if (path.length === 1) {
        path.push(e.point)
      }
      this.$set(path, path.length - 1, e.point)
    },
    newPolyline (e) {
      if (!this.polyline.editing) {
        return
      }
      const { paths } = this.polyline
      if (!paths.length) {
        paths.push([])
      }
      const path = paths[paths.length - 1]
      path.pop()
      if (path.length) {
        paths.push([])
      }
    },
    paintPolyline (e) {
      if (!this.polyline.editing) {
        return
      }
      const { paths } = this.polyline
      !paths.length && paths.push([])
      paths[paths.length - 1].push(e.point)
      console.log(paths);
    },
    getXy () {
      console.log(this.polyline.paths);
    },
  },
};
</script>

<style scoped>
#allmap {
  width: 100%;
  height: 56vh;
}
/* .Overlay {
  margin: 10px 0px;
  position: absolute;
  z-index: 99;
  right: 30px;
} */
</style>